<template>
  <div class="wrap">
    <div class="facilityBox">
      <!-- form表单 -->
      <el-form class="manageForm" :model="manageForm" :inline="true">
        <el-form-item label="企业名称：" prop="EnterpriseFullName">
          <el-input class="fromInp" v-model="manageForm.EnterpriseFullName" placeholder="输入企业名称"
            @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="LegalPersonlPhone">
          <el-input class="fromInp" v-model="manageForm.LegalPersonlPhone" placeholder="输入手机号"
            @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <el-form-item label="提交日期：" prop="TaskDatetime">
          <el-date-picker v-model="manageForm.TaskDatetime" type="daterange" range-separator="至"
            start-placeholder="开始日期" value-format="yyyy-MM-dd" end-placeholder="结束日期" @change="searchChange()">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading">
        <el-table-column type="index" align="center" label="序号" width="50" fixed show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" width="300" fixed show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" width="200"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" width="420" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="SubmitDatetime" label="提交时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Status" label="审核状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.Status }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ConfigStatus" label="设置状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.ConfigStatus }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="VerifyRemark" label="备注" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="操作" width="140" fixed="right">
          <template slot-scope="scope">
            <!-- <el-button type="primary" size="small" disabled @click="goFacility(scope.row)" icon="el-icon-setting">设置</el-button> -->
            <el-button type="primary" size="small" @click="goFacility(scope.row, pagination.page)"
              icon="el-icon-document">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange" :current-page.sync="pagination.page"
          :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="50%" class="deep_dialog">
      <span slot="title">设置分成比例</span>
      <el-tabs v-model="dialogName" @tab-click="handleClicks($event)" style="margin-top: 1vh">
        <el-tab-pane label="设置费率" name="first">
          <div class="dialog_box">
            <div class="dialogRole">
              <strong>当前角色：</strong><u>服务商</u><br />
            </div>
            <div class="dialogInfo">
              <div>
                <strong>注册ID：</strong><u>{{ tenant.LegalPersonlPhone }} </u><br />
                <strong>企业名称：</strong><u> {{ tenant.EnterpriseFullName }}</u><br />
                <strong>身份证号码：</strong><u> {{ tenant.LegalPersonIIDCard }}</u><br />
              </div>
              <div>
                <strong>姓名： </strong><u>{{ tenant.LegalPersonIName }} </u><br />
                <strong>电话：</strong><u>{{ tenant.LegalPersonlPhone }} </u><br />
                <strong>信用代码：</strong><u> {{ tenant.EnterpriseCreditCode }}</u><br />
              </div>
            </div>
            <el-table :data="tenant.eInvoiceTypeList" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
              v-loading="loading" max-height="300px">
              <el-table-column align="center" prop="EnterpriseAddress" label="发票-业务-场景" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div v-if="scope.row.TaskSceneName">{{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} /
                    {{ scope.row.TaskSceneName }}</div>
                  <div v-else>{{ scope.row.InvoiceTypeName }}</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="平台-服务商结算费率" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input class="fromInp" v-model="scope.row.Rate" placeholder="输入服务商结算费率（固定扣点）" maxlength="5"
                    onkeyup="value=value.replace(/[^0-9]+(\.?)+([0-9]{3})$|[^0-9]+(\..?)$|00/,'')"
                    oninput="if(value > 99 || value < 0 ){value = ''}">
                    <template slot="append">%</template>
                  </el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="设置开票最大金额" name="second">
          <div class="dialog_box">
            <el-form :model="commission" :inline="true" style="width:100%;text-align:center;">
              <el-form-item label="当月开票最大金额(￥)：" prop="TotalInvoiceAmount" label-width="162px">
                <el-input class="fromInp" v-model="commission.TotalInvoiceAmount" placeholder="输入当月开票最大金额"
                  maxlength="12" oninput="if(value <= 0 ){value = ''}" style="width: 250px; color: #000"
                  @input="inputAmount">
                  <template slot="append">.00</template>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="">
                <el-input class="fromInp" v-model="money" placeholder="人民币" oninput="if(value < 0 ){value = ''}"
                  style="width: 420px" disabled @input="inputAmount">
                </el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-alert style="margin-top:10px;" type="warning" :closable="false">
        <span slot="title">
          <span>温馨提示：</span><br />
          <span v-if="dialogName == 'first'">
            1.请客服查阅并保存平台、服务商之间协议；<br />
            2.所有结算费率若不填写，默认为法定税率；<br />
            若填写X%，则该服务商与平台导流的货主间业务，统一按X%获得收益；<br />
            该服务商与自行推荐的货主间业务，统一按服务商-货主间费率获得收益；<br />
            3.平台技术服务费费率若不填写，默认为0.30%；
          </span>
          <span v-else>
            1.客服将先和市场部、财务部沟通确认此服务商缴纳的保证金；<br />
            2.客服再次和服务商沟通确认可开票金额,然后设置；<br />
            3.服务商每个月（每月1日00:00至下个月1日00:00）的开票总额不能超过（小于等于）设置的【当月可开票最大金额】
            即【当月累计已开票金额】+【当月剩余开票金额】≤【当月可开票最大金额】；<br />
            4.设置完，且平台与服务商均同意合作后，立即生效
          </span><br />
        </span>
      </el-alert>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="dialogName == 'first'" @click="dialogVisible = false">取 消</el-button>
        <el-button v-else @click="dialogName = 'first'">上一步</el-button>
        <el-button v-if="dialogName == 'first'" type="primary" @click="nextClick(tenant.eInvoiceTypeList)">下一步
        </el-button>
        <el-button v-else type="primary" @click="UpdateCommission(tenant.eInvoiceTypeList)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import verify from "@/utils/verify";
import { _convertCurrency } from "@/utils/utils";
import {
  BySupplierList,
  UpdateCommission,
  getPlatFormBy
} from "@/api/auditInfo/certified/index";
export default {
  data() {
    return {
      // 费率集合
      commission: {
        RateMinimum: "",
        Rate: "",
        TotalInvoiceAmount: "",
      },
      // 分成模式弹窗
      dialogVisible: false,
      // 选中的分成模式
      dialogName: "first",
      // 分页数据
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 筛选条件数据
      manageForm: {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
      },
      // 数据列博鳌
      tableData: [],
      //当前激活哪个tabs页
      activePage: "basicInfo",
      // 设置状态
      ConfigStatus: 1,
      // 当前被查看数据的ID
      CustomerID: "",
      CommissionType: "01",
      // 单条数据信息
      tenant: "",
      money: "",
      loading: true,
    };
  },
  created() {
    // 数据列表
    this.BySupplierList();
  },
  methods: {
    searchChange() {
      this.search()
    },
    // 当月开票最大金额
    inputAmount(e) {
      this.money = _convertCurrency(e);
    },
    // tab切换
    handleClicks(e) {
      if (e.index == '0') {
        this.dialogName = 'first'
      } else {
        this.nextClick(this.tenant.eInvoiceTypeList)
      }
    },
    // 设置发票费率
    nextClick(rateData) {
      let rateIndex = rateData.length - 1;
      if (rateData[0].Rate <= 0) {
        this.$message({
          message: `请输入平台技术服务费费率`,
          type: 'warning'
        })
        return;
      }
      rateData.forEach((item, index) => {
        // 移除平台技术服务费
        if (item.DefaultRate) {
          // 判读输入的值是否和费率相等
          if (Number(item.Rate) < item.DefaultRate) {
            // 判断是否为最后一个数据
            if (rateIndex == index) {
              this.dialogName = 'second'
            }
          } else {
            this.$confirm('建议：服务商的结算费率应小于发票费率。如您坚持设置此数值，请与各相关方协商好。谢谢~', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.dialogName = 'second'
            }).catch(() => {
              this.dialogName = 'first'
            })
          }
        }
      })
    },
    // 设置分成比例
    UpdateCommission(rateData) {
      let data = {
        CustomerID: this.CustomerID,
        Rate: rateData[0].Rate,
        configList: [],
        TotalInvoiceAmount: this.commission.TotalInvoiceAmount,
      };
      for (let i = 0; i < rateData.length; i++) {
        let item = rateData[i]
        if (item.TaskSceneTypeID) {
          let params = {
            TaskSceneTypeID: item.TaskSceneTypeID,
            RateMinimun: verify._radix(item.Rate)
          }
          data.configList.push(params)
        }
      }
      UpdateCommission({ Json: JSON.stringify(data) }).then((res) => {
        let data = {
          EnterpriseFullName: this.manageForm.EnterpriseFullName,
          LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
          SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
          SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
          pageSize: this.pagination.pagesize,
          pageIndex: 1,
          ConfigStatus: this.ConfigStatus,
        };
        this.BySupplierLists(data);
        setTimeout(() => {
          this.dialogVisible = false;
        });
      });
    },
    // 显示分成比例弹窗
    setClick(item) {
      this.CustomerID = item.UserID;
      this.tenant = item;
      getPlatFormBy({ UserID: item.UserID }).then(res => {
        let ratelist = [
          {
            InvoiceTypeName: '平台技术服务费费率',
            Rate: res.eInvoiceTypeList[0].Rate ? res.eInvoiceTypeList[0].Rate : '0.30'
          }
        ]
        res.eInvoiceTypeList.forEach(item => {
          item.Rate = item.RateMinimun ? item.RateMinimun : ''
          ratelist.push(item)
        })
        this.commission.TotalInvoiceAmount = res.eInvoiceTypeList[0].TotalInvoiceAmount
        this.money = _convertCurrency(res.eInvoiceTypeList[0].TotalInvoiceAmount)
        this.tenant.eInvoiceTypeList = ratelist
        this.dialogVisible = true;
      })
    },
    // 清空搜索条件
    resetForm() {
      this.manageForm = {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
      };
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
        ConfigStatus: this.ConfigStatus,
      };
      this.BySupplierLists(data);
    },
    // 搜索
    search() {
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
        ConfigStatus: this.ConfigStatus,
      };
      this.BySupplierLists(data);
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: e,
        ConfigStatus: this.ConfigStatus,
      };
      this.BySupplierLists(data);
    },
    // 设置服务商分成比例列表
    BySupplierList() {
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        ConfigStatus: this.ConfigStatus,
      };
      this.BySupplierLists(data);
    },
    // 设置服务商分成比例列表
    BySupplierLists(data) {
      this.loading = true
      BySupplierList({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.enterpriseBase;
        this.pagination.total = Number(res.totalRowCount);
      }).finally(() => {
        this.loading = false;
      })
    },
    // 详情跳转
    goFacility(item, pageIndex) {
      this.$router.push({
        path: "/auditInfo/setService/serviceInfo",
        query: {
          UserID: item.UserID,
          urlType: this.activePage,
          pageIndex
        }
      });
    },
  },
};
</script>

<style lang="scss">
.wrap {
  .el-input.is-disabled .el-input__inner {
    color: #333;
  }
}
</style>

<style lang="scss" scoped>
.mdoeBox {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 20px;
}

.mdoeDiv {
  width: 80%;
  margin: 10px 0 0;
  line-height: 26px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10%;
  color: #333;

  span {
    color: red;
    font-size: 16px;
  }
}

.dialog_box {
  font-size: 14px;
  line-height: 26px;
  width: 100%;
  background: #f8f8f8;
  padding: 20px 5%;
  box-sizing: border-box;
  color: #666;
  border-radius: 5px;

  .dialogRole {
    width: 100%;
    text-align: center;
  }

  .dialogInfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}

.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 0px 20px 20px;
  }
}
</style>